import 'trix'
import '@rails/actiontext'
import SvelteLoader from '@/global/javascript/svelte_loader'
import { initDatadog } from '../utility/DataDogTracking/datadog'
import '../utility/flatplickr/datepicker'

// https://vite-ruby.netlify.app/guide/advanced.html#import-every-component
// Could be optimized to use one entrypoint per component

const viewComponentJSModules = import.meta.glob(
  '../../components/**/*_component.js',
  { eager: true }
);

const viewComponentCSSModules = import.meta.glob(
  '../../components/**/*_component.css',
  { eager: true }
);
{ viewComponentJSModules, viewComponentCSSModules }

import '@/global/javascript/codemirror'

// Hack - the import inside this file won't play nicely with ActiveAdmin/webpacker, post-svelte
if (!window.ActiveAdmin) {
  import('@/global/javascript/mentions')
}

function clearAccessTokenFromLocalStorage() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('selectedContext');
}

document.addEventListener('DOMContentLoaded', () => {
  const logoutButton = document.querySelector("a[href='/users/sign_out']");

  if (logoutButton) {
    logoutButton.addEventListener('click', clearAccessTokenFromLocalStorage)
  }
});

initDatadog()

window.SvelteRailsUJS = SvelteLoader
SvelteLoader.start()
